import React from 'react'
import Content from '../../layouts/Content'

export default function Home() {    
    return (
        <Content title="Dashboard">
            Dashboard
        </Content>
    )
}
