import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactRouter from './router'
import { Error500 } from './views'

export default function App() {
    return (
        <ErrorBoundary FallbackComponent={Error500}>
            <ReactRouter />
        </ErrorBoundary>
    )
}