import React, { useState, useEffect } from 'react';
import { Alert, SwAlert } from '../../../components';
import axios from '../../../configs/axios';

export default function DeviceWebhook({device}) {
    const [webhooks, setWebhooks] = useState([]);
    const [page, setPage] = useState('list');
    const [url, setUrl] = useState('');

    const record = { device_id: device.device_id, hook_url: url }

    const saveWebhook = async () => {
        try {
            const res = await axios.post('webhook/create', record);
            if (res.data.meta.status === 'success') {
                setPage('list')
                getWebhooks();
                setUrl('');
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const getWebhooks = async () => {
		try {
            let res = await axios.get('webhook', {
                params: {
                    device_id: device.device_id
                }
            })
            setWebhooks(res.data.data)
		} catch (e) {
			console.log(e)
		}
    }

    const deleteWebhook = (id) => {
        SwAlert.fire({
            title: 'Delete',
            text: 'Are you sure to delete this data?',
            showCancelButton: true,
            buttonsStyling: false,
            reverseButtons: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'w-25 btn btn-danger btn-sm',
                cancelButton: 'w-25 btn btn-default btn-sm me-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios.delete(`webhook/delete/${id}`)
                    if (res.data.meta.status === 'success') {
                        getWebhooks()
                        Alert.success('Webhook berhasil dihapus.', {autoClose: true})
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }
    
    useEffect(() => {
        getWebhooks();
    }, [])

    return (
        <div className="card device-container-detail mb-3 overflow-hidden">
            <div className="card-body">
                {page === 'list' ?
                    <>
                        <div className="d-flex justify-content-end align-items-center mb-3">
                            <button onClick={()=>setPage('create')} className="btn btn-sm btn-primary">Add Webhook</button>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="text-center" width={50}>No</th>
                                        <th>Url</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {webhooks.map((webhook, index) =>
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{webhook.hook_url}</td>
                                            <td>
                                                <button className="text-danger btn" onClick={() => deleteWebhook(webhook.hook_id)}>
                                                    <i className="bi-trash-fill"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                    :
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="mb-0">Create Webhook</h5>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inputUrl" className="form-label">Url Webhook</label>
                            <input onChange={(e) => setUrl(e.target.value)} className="form-control" id="inputUrl" required/>
                        </div>
                        <div className="d-flex justify-content-start">
                            <button onClick={()=>setPage('list')} className="btn btn-default me-2">Cancel</button>  
                            <button onClick={saveWebhook} className="btn btn-success">Save</button>  
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
