import React, { useState, useEffect } from 'react';
import axios from '../../../configs/axios';

export default function DeviceAutoReply({device}) {
    const [autoReplies, setAutoReplies] = useState([]);
    const [page, setPage] = useState('list');
    const [chatKeyword, setChatKeyword] = useState(null);
    const [chatMessage, setChatMessage] = useState(null);

    const record = { device_id: device.device_id, reply_keyword: chatKeyword, reply_message: chatMessage }

    const store = async () => {
        try {
            const res = await axios.post('auto-reply/create', record);
            if (res.data.success) {
                setPage('list')
                getAutoReplies();
                setChatKeyword('');
                setChatMessage('');
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const detailReply = (reply) => {
        setChatKeyword(reply.reply_keyword);
        setChatMessage(reply.reply_message);
    }

    const getAutoReplies = async () => {
		try {
            let res = await axios.get('auto-reply/device/'+device.device_id)
            setAutoReplies(res.data)
            console.log(res.data)
		} catch (e) {
			console.log(e)
		}
    }
    
    useEffect(() => {
        getAutoReplies();
    }, [])

    return (
        <div className="card device-container-detail mb-3 overflow-hidden">
            <div className="row g-0">
                <div className="col-md-7 device-container-left ">
                    <div className="card-body">
                        {page === 'list' ?
                            <>
                                <button onClick={()=>setPage('create')} className="btn btn-sm btn-default mb-3">New auto reply</button>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width={50}>No</th>
                                                <th>Keyword</th>
                                                <th>Message</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {autoReplies.map((reply, index) => 
                                                <tr key={index}>
                                                    <td className="text-center">{index+1}</td>
                                                    <td>
                                                        {reply.reply_keyword}
                                                    </td>
                                                    <td>
                                                        {reply.reply_message}
                                                    </td>
                                                    <td>
                                                        <span onClick={() => detailReply(reply)} className="text-secondary me-2">
                                                            <i className="bi-eye-fill"></i>
                                                        </span>
                                                        <a className="text-warning me-2" href={'/device/' + device.device_id}>
                                                            <i className="bi-pencil-fill"></i>
                                                        </a>
                                                        <a className="text-danger me-2" href={'/device/' + device.device_id}>
                                                            <i className="bi-trash-fill"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            : 
                            <>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="inputKeyword" className="form-label">Keyword</label>
                                    <input onChange={(e) => setChatKeyword(e.target.value)} type="text" className="form-control" id="inputKeyword" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputMessage" className="form-label">Message</label>
                                    <textarea onChange={(e) => setChatMessage(e.target.value)} className="form-control" id="inputMessage"></textarea>
                                </div>
                                <button onClick={store} className="btn btn-success">Save</button>    
                            </div>

                            </>
                            }
                    </div>
                </div>
                <div className="col-md-5 device-container-right">
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center my-4 my-md-0">
                            <div className="device-container shadow">
                                <div className="device-screen">
                                    <div className="wa-content-chat">
                                        <div className="wa-chat-header">
                                            <div className="d-flex align-items-center">
                                                <div className="back-icon">
                                                    <i className="bi-arrow-left"></i>
                                                </div>
                                                <div className="profile-wa">
                                                    <i className="bi-person-circle"></i>
                                                </div>
                                                <h6 className="ms-3 mb-0">Customer</h6>
                                            </div>
                                        </div>
                                        <div className="wa-chat-body">
                                            {chatKeyword ?
                                                <div className="chat-inbox">
                                                    <span>{chatKeyword}</span>
                                                </div>
                                                : null}
                                            {chatMessage ?
                                                <div className="chat-reply d-flex justify-content-end">
                                                    <span>{chatMessage}</span>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="wa-chat-footer">
                                            <div className="chat-form"></div>
                                            <div className="send-icon">
                                                <i className="bi-forward-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
