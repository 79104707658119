
import React, { useContext } from 'react'
import axios from '../../configs/axios';
import { AuthContext } from '../../contexts/Auth'

export default function Navbar(props) {
    const { auth, setAuth } = useContext(AuthContext);

    const logout = async () => {
        try {
            const res = await axios.get('auth/logout')
            if (res.data.meta.status === 'success') {
                setAuth({type: 'LOGOUT'})
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <header className="navbar">
            <div className="navbar-menu-container d-flex justify-content-between align-items-center">
                <div className="brand">
                    <button className="btn btn-navbar p-0" onClick={props.collapseSidebar}>
                        <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </button>
                    <a href="/#" className="brand-logo ms-3">
                        <img src="/assets/img/logo.png" alt="" height={20} />
                    </a>
                </div>
                {auth.isAuthenticated &&
                    <div className="dropdown">
                        <a href="/#" className="d-block link-dark text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={auth.user.profile_picture} alt="mdo" width={32} height={32} className="rounded-circle" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownUser1" style={{}}>
                            <li><a className="dropdown-item" href="/#">{auth.user.name}</a></li>
                            <li><a className="dropdown-item" href="/#">Settings</a></li>
                            <li><a className="dropdown-item" href="/#">Profile</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><button onClick={() => logout()} className="dropdown-item" href="/#">Sign out</button></li>
                        </ul>
                    </div>
                }
            </div>
        </header>
    )
}
