const DateObj = {
    format: null
}

DateObj.format = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const today = new Date(date);
    const twoDigits = (number) => {
        return ('0' + number).slice(-2);
    }

    const dateFormat = twoDigits(today.getDate()) + ' ' +
        monthNames[today.getMonth()] + ' ' +
        today.getFullYear() + ' ' +
        twoDigits(today.getHours()) + ':' +
        twoDigits(today.getMinutes());

    return dateFormat;
}

export default DateObj;