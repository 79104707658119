import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import { Alert } from '../components';

export default function Content({children, title}) {
    document.title = title;
    const [pageLoad, setPageLoad] = useState(false);
    const nodeRef = useRef(null);

    useEffect(() => {
        setPageLoad(true)
    }, [])

    return (
        <CSSTransition
            in={pageLoad}
            timeout={300}
            classNames="page-content"
            unmountOnExit
            nodeRef={nodeRef}
        >
            <div ref={nodeRef} className="page-content">
                <Alert.Container/>
                {children}
            </div>
        </CSSTransition>
    )
}
