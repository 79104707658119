import React from 'react'
import { NavLink } from 'react-router-dom'

export default function DeviceNotFound() {
    return (
        
        <div className="card">
            <div className="py-5 text-center">
                <h2 className="fw-bold">Device not found</h2>
                <p className="text-black-50">Device you are looking for was not found.</p>
                <NavLink to="/device" className="btn btn-default mt-2">Back to devices</NavLink>
            </div>
        </div>

    )
}
