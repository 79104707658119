import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Alert } from '../../../components';
import axios from '../../../configs/axios';
import Content from '../../../layouts/Content';

export default function ApiKeyCreate() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const saveApiKey = async (data) => {
        setLoading(true)

        const res = await axios.post('api-token/create', data);
        
        try {
            if (res.data.meta.status === 'success') {
                history.push('/api-key');
                Alert.success('Api key berhasil dibuat.', {autoClose: true})
            } else {
                const response = res.data.data
                for (let i = 0; i < response.length; i++) {
                    const error = response[i];
                    setError(error.param, {
                        type: "manual",
                        message: error.msg,
                    })
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
        
    }

    return (
        <Content title="Api Key">
            <div className="col-md-8 offset-md-2">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Create Api Key</h5>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(saveApiKey)}>
                            <div className="mb-3">
                                <label className="form-label">Api Name</label>
                                <input className={`form-control ${errors.api_name ? "is-invalid" : ""}`} placeholder="Enter your api name"
                                    {...register("api_name", {
                                        required: "Api name is a required"
                                    })}
                                />
                                {errors.api_name && <div className="invalid-feedback">{errors.api_name.message}</div>}
                            </div>
                            <button type="submit" disabled={loading} className="btn btn-primary fw-500">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </Content>
    )
}
