import React from 'react'

export default function DeviceDetailContent({device}) {
    return (
        <div className="card device-container-detail mb-3 overflow-hidden">
            <div className="row g-0">
                <div className="col-md-7 device-container-left ">
                    <div className="d-flex justify-content-between align-items-center border-bottom px-3 py-2">
                        <h6 className="card-title mb-0">{device.device_name}</h6>
                    </div>
                    <div className="card-body">
        
                        <dl className="row mb-0">
                            <dt className="col-sm-3">ID</dt>
                            <dd className="col-sm-9">{device.device_id}</dd>
                            <dt className="col-sm-3">Phone number</dt>
                            <dd className="col-sm-9">+{device.device_number}</dd>
                            <dt className="col-sm-3">WhatsApp ID </dt>
                            <dd className="col-sm-9">{device.device_number}@c.us</dd>
                            <dt className="col-sm-3">Status </dt>
                            <dd className="col-sm-9">{device.device_status}</dd>
                            <dt className="col-sm-3">Description </dt>
                            <dd className="col-sm-9">{device.device_description}</dd>
                        </dl>
                    </div>
                </div>
                <div className="col-md-5 device-container-right">
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center my-4 my-md-0">
                            <div className="device-container shadow">
                                <div className="device-screen">
                                    <div className="wa-content-top d-grid justify-content-center align-items-center">
                                        <div className="w-100 d-flex justify-content-center align-items-center">
                                            <img src={process.env.REACT_APP_API_URL+'/devices/profile-pic/' + device.device_profile_pic} alt={device.device_name} width={120} height={120} className="rounded-circle" />
                                        </div>
                                        <h3>{device.device_name}</h3>
                                    </div>
                                    <div className="wa-content-about">
                                        <div className="wa-about-list">
                                            <span className="icon">
                                                <i className="bi-info-circle-fill"></i>
                                            </span>
                            -
                        </div>
                                        <div className="wa-about-list">
                                            <span className="icon">
                                                <i className="bi-telephone-fill"></i>
                                            </span>
                            +{device.device_number}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
