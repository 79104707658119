import  React, { createContext, useReducer } from 'react';

const AuthContext = createContext();

const userLocal = JSON.parse(localStorage.getItem('u_lgn'))

const initialState = {
    isAuthenticated: userLocal ? userLocal.lgn : false,
    user: {
        user_id: null,
        name: userLocal ? userLocal.u.name : null,
        email: userLocal ? userLocal.u.email : null,
        profile_picture: userLocal ? userLocal.u.profile_picture : null
    }
}

const authReducer = (state, action) => {
    switch(action.type) {
        case "AUTHENTICATED":
            const userLogin = {
                lgn: true,
                u: {
                    name: action.user.name,
                    email: action.user.email,
                    profile_picture: action.user.profile_picture
                }
            }
            localStorage.setItem("u_lgn", JSON.stringify(userLogin))
            return {
                ...state,
                isAuthenticated: true,
                user: action.user
            }
        case "LOGOUT":
            localStorage.removeItem("u_lgn")
            return {
                ...state,
                isAuthenticated: false,
                user: null
            }
        default:
            return state
    }
}
  
function AuthProvider({ children }) {
    const [auth, setAuth] = useReducer(authReducer, initialState)
    const value = { auth, setAuth }
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthProvider, AuthContext }
