import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react';
import { useParams } from 'react-router';
import socket from '../../configs/socketIo';
import { NavLink } from 'react-router-dom';
import Content from '../../layouts/Content';
import axios from '../../configs/axios';

export default function DeviceAuthorize() {
    const { id } = useParams()
    const [qrcodeCheck, setQrcodeCheck] = useState(false)
    const [readyCheck, setReadyCheck] = useState(false)
    const [qrcode, setQrcode] = useState(null)
    const [deviceId] = useState(id)
    const [loading, setLoading] = useState(true)
    const [qrcodeScanned, setQrcodeScanned] = useState(false)
    const [page, setPage] = useState('qrcode')

    const authenticatedPage = () => {
        setPage('authenticated');
        setQrcodeCheck(true)
        setReadyCheck(true)
    }
    
    const requestQr = async () => {
        setLoading(true)
        try {
            const record = { device_id: id };
            const res = await axios.post('/device/qr/create', record);
            if (res.data.meta.status === 'success') {
                authenticatedPage()
            }
            else {
                setPage('error');
            }
            setLoading(false)
        } catch (e) {
            setPage('error');
            setLoading(false)
        }
    }

    useEffect(() => {
        requestQr()
    }, [])

    useEffect(() => {
        socket.on("connect", () => {
            socket.on("device-sync-qr-code", data => {
                if (data.device_id === deviceId) {
                    setQrcode(data.qrcode)
                }
            });
    
            socket.on("device-scanned-qr-code", data => {
                if (data.device_id === deviceId) {
                    setQrcodeScanned(true)
                }
            });
        });
    });

    const reloadRequestQr = () => {
        setQrcode(null)
        setPage('qrcode');
        setQrcodeCheck(false)
        setReadyCheck(false)
        setQrcodeScanned(false)
        requestQr()
    }

    return (
        <Content className="page" title="Device">
            <div className="col-lg-8 offset-lg-2">
                <div className="create-device-left-content">
                    <ul className="d-flex justify-content-around">
                        <li className={qrcodeCheck ? 'col active' : 'col'}>
                            <div className="icon">
                                <i className={qrcodeCheck ? "bi-check-circle-fill text-success" : page === 'qrcode' ? "bi-record-circle text-primary" : "bi-circle text-muted"}></i>
                            </div>
                            <h6>Scan QRCode</h6>
                            {page === 'qrcode' &&
                                <small>Scan QRCode to authorize your whatsapp number</small>}
                        </li>
                        <li className={readyCheck ? 'col active' : 'col'}>
                            <div className="icon">
                                <i className={readyCheck ? "bi-check-circle-fill text-success" : page === 'authenticated' ? "bi-record-circle text-primary" : "bi-circle text-muted"}></i>
                            </div>
                            <h6>Ready</h6>
                            {page === 'authenticated' ?
                            <small>Your device authenticated, ready to use.</small>
                            : null}
                        </li>
                    </ul>
                </div>
                <div className="card overflow-hidden">
                    <div className="create-device-right">
                        <div className="row justify-content-center align-items-center create-device-right-content">
                        {
                            page === 'qrcode' ?
                                <div className="col-md-12 col-lg-10">
                                    {qrcode && !qrcodeScanned ?
                                        <div className="text-center">     
                                            <h5>Connect the number by scanning the QR code with the camera.</h5>
                                            <p>To prevent conflict, please close all existing WhatsApp Web open sessions</p>
                                            <QRCode size={200} value={qrcode} imageSettings={{
                                                src: process.env.REACT_APP_URL+"/wa-icon.svg",
                                                x: null,
                                                y: null,
                                                height: 48,
                                                width: 48,
                                                excavate: false,
                                            }}/>
                                        </div> 
                                            :
                                        loading ?
                                            <div className="text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        : null
                                    }
                                </div>
                            : page === 'authenticated' ?
                                <div className="col-md-8 col-lg-6">
                                    <div className="text-center">
                                        <h5>Your WhatsApp number is now connected.<br />
                                        You can start sending and receiving messages through the platform.
                                        </h5>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <NavLink to={'/device/'+deviceId+'/detail'} className="btn btn-sm btn-primary">Show Device</NavLink>
                                    </div>
                                </div>
                            : page === 'error' ?
                                <div className="col-md-8 col-lg-6">
                                    <div className="text-center">
                                        <h5>Something went wrong<br />
                                        Please try again.
                                        </h5>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <button onClick={() => reloadRequestQr()} className="btn btn-sm btn-primary">Try again</button>
                                    </div>
                                </div>          
                            : null
                        }
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
