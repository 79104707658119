
import React, { useContext } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useForm } from 'react-hook-form';
import { useHistory, NavLink} from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth';
import Content from '../../../layouts/Content';
import axios from '../../../configs/axios';

export default function Login() {
    const history = useHistory()
    const handleError = useErrorHandler()
    const { setAuth } = useContext(AuthContext);
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const loginAction = async (data) => {
        try {
            const res = await axios.post('auth/login', data);
            if (res.data.meta.status === 'success') {
                const user = res.data.data
                setAuth({
                    type: "AUTHENTICATED",
                    user: user,
                })
                history.push('/');
            } else {
                const response = res.data.data
                for (let i = 0; i < response.length; i++) {
                    const error = response[i];
                    setError(error.param, {
                        type: "manual",
                        message: error.msg,
                    })
                }
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <Content title="Login">
            <div className="container py-5">
                <div className="col-md-6 offset-md-3">
                    <div className="card p-3 p-md-5">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(loginAction)}>
                                <h1 className="text-center">Login</h1>
                                <div className="text-muted text-center mb-4">
                                    Please login first.
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input className={`form-control ${errors.email ? "is-invalid" : ""}`} placeholder="Enter your email address"
                                        {...register("email", {
                                            required: "Email is a required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            }
                                        })}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} placeholder="Enter your password"
                                        {...register("password", {
                                            required: "Password is a required",
                                            minLength: {
                                                value: 6,
                                                message: "Min length is 6"
                                            }
                                        })}
                                    />
                                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" defaultValue id="customCheck1" />
                                            <label className="form-check-label fw-500" htmlFor="customCheck1">Remember me</label>
                                        </div>
                                        <a href="/#" className="ms-auto text-muted">Forgot password?</a>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Login</button>
                                <div className="text-center text-muted">
                                    Don't have an account yet? <NavLink exact to="/register">Register</NavLink>.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
