import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap';
import './assets/fonts/font.css';
import './assets/css/main.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/style.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
