import axiosConfig from 'axios';

const axios = axiosConfig.create({
    baseURL: `${process.env.REACT_APP_API_URL}/bkd`,
    withCredentials: true
});

axios.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    // if (error.response.status === 401) {
    //     // Alert.error('Sesi anda habis, silakan login kembali', {autoClose: true})
    // }
    // else {
        
    // }

    return Promise.reject(error);
});

export default axios;