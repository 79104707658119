import React, { useState, useEffect } from 'react';
import axios from '../../../configs/axios';

export default function DeviceMessage({ device }) {
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState('list');
    const [chatNumber, setChatNumber] = useState(null);
    const [chatMessage, setChatMessage] = useState(null);

    const record = { device_id: device.device_id, message_to: chatNumber, message: chatMessage }

    const store = async () => {
        try {
            const res = await axios.post('message/create', record);
            if (res.data.success) {
                setPage('list')
                getMessages();
                setChatNumber('');
                setChatMessage('');
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    const detailMessage = (message) => {
        setChatNumber(message.message_to);
        setChatMessage(message.message);
    }

    const getMessages = async () => {
		try {
            let res = await axios.get('message/device/'+device.device_id)
            setMessages(res.data)
		} catch (e) {
			console.log(e)
		}
    }
    
    useEffect(() => {
        getMessages();
    }, [])

    return (
        <div className="card device-container-detail mb-3 overflow-hidden">
            <div className="row g-0">
                <div className="col-md-7 device-container-left ">
                    <div className="card-body">
                        {page === 'list' ?
                            <>
                                <button onClick={()=>setPage('create')} className="btn btn-sm btn-default mb-3">New message</button>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width={50}>No</th>
                                                <th>To</th>
                                                <th>Message</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {messages.map((message, index) => 
                                                <tr key={index}>
                                                    <td className="text-center">{index+1}</td>
                                                    <td>
                                                        {message.message_to}
                                                    </td>
                                                    <td>
                                                        {message.message}
                                                    </td>
                                                    <td>
                                                        <span onClick={() => detailMessage(message)} className="text-secondary me-2">
                                                            <i className="bi-eye-fill"></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            : 
                            <>
                            <div>
                                <div className="mb-3">
                                    <label htmlFor="inputNumber" className="form-label">WA Number</label>
                                    <input onChange={(e) => setChatNumber(e.target.value)} type="text" className="form-control" id="inputNumber" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputMessage" className="form-label">Message</label>
                                    <textarea onChange={(e) => setChatMessage(e.target.value)} className="form-control" id="inputMessage"></textarea>
                                </div>
                                <button onClick={store} className="btn btn-success">Send</button>    
                            </div>

                            </>
                            }
                    </div>
                </div>
                <div className="col-md-5 device-container-right">
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center my-4 my-md-0">
                            <div className="device-container shadow">
                                <div className="device-screen">
                                    <div className="wa-content-chat">
                                        <div className="wa-chat-header">
                                            <div className="d-flex align-items-center">
                                                <div className="back-icon">
                                                    <i className="bi-arrow-left"></i>
                                                </div>
                                                <div className="profile-wa">
                                                    <i className="bi-person-circle"></i>
                                                </div>
                                                <h6 className="ms-3 mb-0">{chatNumber ? chatNumber : 'Customer'}</h6>
                                            </div>
                                        </div>
                                        <div className="wa-chat-body">
                                            {chatMessage ?
                                                <div className="chat-reply d-flex justify-content-end">
                                                    <span>{chatMessage}</span>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="wa-chat-footer">
                                            <div className="chat-form"></div>
                                            <div className="send-icon">
                                                <i className="bi-forward-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}