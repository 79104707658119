import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AlertService, AlertType } from './AlertService';

const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: true
};

const AlertContainer = ({ id, fade }) => {
    const history = useHistory();
    const [alerts, setAlerts] = useState([]);

    const cssClasses = (alert) => {
        if (!alert) return;

        const classes = ['alert', 'alert-dismissible', ];
                
        const alertTypeClass = {
            [AlertType.Success]: 'alert-success',
            [AlertType.Error]: 'alert-danger',
            [AlertType.Info]: 'alert-info',
            [AlertType.Warning]: 'alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (fade) {
            classes.push('fade show');
        }

        return classes.join(' ');
    }

    useEffect(() => {
        const removeAlert = (alert) => {
            if (fade) {
                // fade out alert
                const alertWithFade = { ...alert, fade: true };
                setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));
    
                // remove alert after faded out
                setTimeout(() => {
                    setAlerts(alerts => alerts.filter(x => x !== alertWithFade));
                }, 250);
            } else {
                // remove alert
                setAlerts(alerts => alerts.filter(x => x !== alert));
            }
        }
        // subscribe to new alert notifications
        const subscription = AlertService.onAlert(id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    setAlerts(alerts => {
                        // filter out alerts without 'keepAfterRouteChange' flag
                        const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                        return filteredAlerts;
                    });
                } else {
                    // add alert to array
                    setAlerts(alerts => ([...alerts, alert]));

                    // auto close alert if required
                    if (alert.autoClose) {
                        setTimeout(() => removeAlert(alert), 3000);
                    }
                }
            });

        // clear alerts on location change
        const historyUnlisten = history.listen(() => {
            AlertService.clear(id);
        });

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            historyUnlisten();
        };
    }, [history, id, fade]);

    if (!alerts.length) return null;

    return (
        <div className="alert-container">
            {alerts.map((alert, index) =>
                <div key={index} className={cssClasses(alert)} role="alert">
                    {alert.message}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
                </div>
            )}
        </div>
    );
}

AlertContainer.propTypes = propTypes;
AlertContainer.defaultProps = defaultProps;

export default AlertContainer;