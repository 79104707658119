import { useEffect, useState } from "react";

export default function Backdrop({ show = false, onClick = null, closeOnClick = true }) {
    
    const [showBackdrop, setShowBackdrop] = useState(show);

    const toggleBackdrop = () => {
        setShowBackdrop(false)
    };

    useEffect(() => {
        setShowBackdrop(show);
    }, [show])

    return (
        <>
            {showBackdrop ?
                <div className="sidebar-backdrop" onClick = { closeOnClick && !onClick ? toggleBackdrop : onClick }/>
            : null}
        </>
    );
}
