import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageLoading } from '../../../components';
import Content from '../../../layouts/Content';
import DeviceAutoReply from './DeviceAutoReply';
import DeviceDetailContent from './DeviceDetailContent';
import DeviceMessage from './DeviceMessage';
import DeviceWebhook from './DeviceWebhook';
import DeviceNotFound from './DeviceNotFound';
import axios from '../../../configs/axios';

export default function DeviceDetail() {
    const { id, page } = useParams();
    const [device, setDevice] = useState(null);
    const [loading, setLoading] = useState(false);

    const detail = async () => {
        setLoading(true)
        try {
            const res = await axios.get('device/' + id);
            setTimeout(() => {
                setDevice(res.data.data)
                setLoading(false)
            }, 1000);
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        detail()
    }, [])

    return (
        <Content title="Detail Device">
            <div className="nav nav-pills mb-3" id="nav-tab" role="tablist">
                <NavLink to={`/device/${id}/detail`} className={'nav-link ' + (page === 'detail' ? 'active' : '')} type="button" role="tab" aria-controls="nav-detail">Detail</NavLink>
                <NavLink to={`/device/${id}/auto-reply`} className={'nav-link ' + (page === 'auto-reply' ? 'active' : '')} type="button" role="tab" aria-controls="nav-auto-reply">Auto Reply</NavLink>
                <NavLink to={`/device/${id}/message`} className={'nav-link ' + (page === 'message' ? 'active' : '')} type="button" role="tab" aria-controls="nav-message">Message</NavLink>
                <NavLink to={`/device/${id}/webhook`} className={'nav-link ' + (page === 'webhook' ? 'active' : '')} type="button" role="tab" aria-controls="nav-message">Webhooks</NavLink>
            </div>
            { device ?
                <>
                    {
                        page === 'detail' ?
                            <DeviceDetailContent device={device}/>
                        : page === 'auto-reply' ?
                            <DeviceAutoReply device={device} />
                        : page === 'message' ?
                            <DeviceMessage device={device} />
                        : page === 'webhook' ?
                            <DeviceWebhook device={device} />
                        : null
                    }
                </>
                :
                <>
                    { loading ?
                        <PageLoading/>
                        : <DeviceNotFound />
                    }
                </>
                
            }
        </Content>
    )
}
