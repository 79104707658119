import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory} from 'react-router-dom';
import axios from '../../../configs/axios';
import Content from '../../../layouts/Content';

export default function Register() {
    const history = useHistory()
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const registerAction = async (data) => {
        const res = await axios.post('auth/register', data);
        
        if (res.data.meta.status === 'success') {
            history.push('/login');
        } else {
            const response = res.data.data
            for (let i = 0; i < response.length; i++) {
                const error = response[i];
                setError(error.param, {
                    type: "manual",
                    message: error.msg,
                })
            }
        }
    }

    return (
        <Content title="Register">
            <div className="container py-5">
                <div className="col-md-6 offset-md-3">
                    <div className="card p-3 p-md-5">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(registerAction)}>
                                <h1 className="text-center">Register</h1>
                                <div className="text-muted text-center mb-4">
                                    Create yout account.
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Name</label>
                                    <input className={`form-control ${errors.name ? "is-invalid" : ""}`} placeholder="Enter name"
                                        {...register("name", {
                                            required: "Name is a required",
                                        })}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input className={`form-control ${errors.email ? "is-invalid" : ""}`} placeholder="Enter your email address"
                                        {...register("email", {
                                            required: "Email is a required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            }
                                        })}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} placeholder="Enter your password"
                                        {...register("password", {
                                            required: "Password is a required",
                                            minLength: {
                                                value: 6,
                                                message: "Min length is 6"
                                            }
                                        })}
                                    />
                                    {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className={`form-check-input ${errors.checkTermCondition ? "is-invalid" : ""}`} type="checkbox" id="checkTermCondition"
                                        {...register("checkTermCondition", {
                                            required: "Please check to agree"
                                            })} />
                                        <label className="form-check-label" htmlFor="checkTermCondition">
                                            I agree to Terms & Privacy Policy.
                                        </label>
                                        {errors.checkTermCondition && <div className="invalid-feedback">{errors.checkTermCondition.message}</div>}
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Register</button>
                                <div className="text-center text-muted">
                                    Already have an Account? <NavLink exact to="/login">Login</NavLink>.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}
