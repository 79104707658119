import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../contexts/Auth'

export default function Guest({render}) {
    const { auth } = useContext(AuthContext)
    const history = useHistory()

    if (auth.isAuthenticated) {
        history.push('/')
    }

    return render
}
