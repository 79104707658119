import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from '../configs/axios'
import { AuthContext } from '../contexts/Auth'

export default function Authenticated({render}) {
    const { auth, setAuth } = useContext(AuthContext)
    const history = useHistory()

    if (!auth.isAuthenticated) {
        history.push('/login')
    }

    const authenticated = (data) => {
        setAuth({
            type: "AUTHENTICATED",
            user: data
        })
    }

    const unAuthenticated = () => {
        setAuth({
            type: "LOGOUT"
        })
    }

    useEffect(() => {
        const getUser = async () => {
            try {
                let res = await axios.post('auth/user')
                if (res.data) {
                    authenticated(res.data)
                } else {
                    unAuthenticated()
                }
            } catch (error) {
                unAuthenticated()
            }
        }

        if (auth.isAuthenticated) {
            getUser()
        }
    }, [])

    return render
}
