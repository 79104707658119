import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import socket from '../../../configs/socketIo';
import { useForm } from "react-hook-form";
import { NavLink } from 'react-router-dom';
import Content from '../../../layouts/Content';
import axios from '../../../configs/axios';

export default function CreateDevice() {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [formCheck, setFormCheck] = useState(false)
    const [qrcodeCheck, setQrcodeCheck] = useState(false)
    const [readyCheck, setReadyCheck] = useState(false)
    const [qrcode, setQrcode] = useState('')
    const [deviceId, setDeviceId] = useState(0)
    const [loading, setLoading] = useState(false)
    const [qrcodeScanned, setQrcodeScanned] = useState(false)
    const [page, setPage] = useState('form')

    const formPage = () => {
        setPage('form');
        setFormCheck(false)
        setQrcodeCheck(false)
        setReadyCheck(false)
    }

    const qrcodePage = () => {
        setPage('qrcode');
        setFormCheck(true)
        setQrcodeCheck(false)
        setReadyCheck(false)
    }

    const authenticatedPage = () => {
        setPage('authenticated');
        setFormCheck(true)
        setQrcodeCheck(true)
        setReadyCheck(true)
    }
    
    const saveRequestQr = async (data) => {
        const record = { device_name: data.device_name, device_description: data.device_description }
        setLoading(true)
        try {
            const res = await axios.post('device/create', record);
            if (res.data.meta.status === 'success') {
                const data = res.data.data;
                const deviceId = data.device_id;
                setQrcode('')
                qrcodePage()
                try {
                    setDeviceId(deviceId)
                    const record = { device_id: deviceId };
                    const resQr = await axios.post('device/qr/create', record);
                    if (resQr.data.meta.status === 'success') {
                        authenticatedPage()
                    }
                    else {
                        formPage()
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            else {
                const response = res.data.data
                console.log(response)
                for (let i = 0; i < response.length; i++) {
                    const error = response[i];
                    setError(error.param, {
                        type: "manual",
                        message: error.msg,
                    })
                }
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    useEffect(() => {
        socket .on("connect", () => {
            console.log('connected to server')
        });

        socket.on("device-sync-qr-code", data => {
            if (data.device_id === deviceId) {
                console.log(data)
                setQrcode(data.qrcode)
            }
        });

        socket.on("device-scanned-qr-code", data => {
            if (data.device_id === deviceId) {
                setQrcodeScanned(true)
            }
        });
    }, [deviceId, qrcode]);

    return (
        
        <Content className="page" title="Device">
            <div className="col-lg-8 offset-lg-2">
                <div className="create-device-left-content">
                    <ul className="d-flex justify-content-around">
                        <li className={formCheck ? 'col active' : 'col'}>
                            <div className="icon">
                                <i className={formCheck ? "bi-check-circle-fill text-success" : page === 'form' ? "bi-record-circle text-primary" : "bi-circle text-muted"}></i>
                            </div>
                            <h6>Describe your device</h6>
                            {page === 'form' &&
                                <small>Describe the device that you will create</small>}
                        </li>
                        <li className={qrcodeCheck ? 'col active' : 'col'}>
                            <div className="icon">
                                <i className={qrcodeCheck ? "bi-check-circle-fill text-success" : page === 'qrcode' ? "bi-record-circle text-primary" : "bi-circle text-muted"}></i>
                            </div>    
                            <h6>Scan QRCode</h6>
                            {page === 'qrcode' &&
                                <small>Scan QRCode to connect your whatsapp number</small>}
                        </li>
                        <li className={readyCheck ? 'col active' : 'col'}>
                            <div className="icon">
                                <i className={readyCheck ? "bi-check-circle-fill text-success" : page === 'authenticated' ? "bi-record-circle text-primary" : "bi-circle text-muted"}></i>
                            </div>
                            <h6>Ready</h6>
                            {page === 'authenticated' &&
                                <small>Your device authenticated, ready to use.</small>}
                        </li>
                    </ul>
                </div>
                    
                <div className="card overflow-hidden">
                    
                    <div className="create-device-right">
                        <div className="row justify-content-center align-items-center create-device-right-content">
                        {
                            page === 'form' ?
                                <div className="col-md-12 col-lg-10">
                                    <div className="text-center">
                                        <h5>Connect new WhatsApp number</h5>
                                        <p>This process will pair an existing WhatsApp number with the platform in a few minutes.</p>
                                    </div>
                                    <form onSubmit={handleSubmit(saveRequestQr)}>
                                        <div className="mb-3">
                                            <label htmlFor="device_name" className="form-label">Device Name</label>
                                            <input className={`form-control ${errors.device_name ? "is-invalid" : ""}`} placeholder="Enter an device name that helps you identify it."
                                                {...register("device_name", {
                                                required: "Device name is a required"
                                                })}
                                            />
                                            {errors.device_name && <div className="invalid-feedback">{errors.device_name.message}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="device_description" className="form-label">Description (Optional)</label>
                                            <input className="form-control" placeholder="Description for your reference about new device"
                                                {...register("device_description")}/>
                                                
                                        </div>
                                        <div className="form-check">
                                            <input className={`form-check-input ${errors.checkTermCondition ? "is-invalid" : ""}`} type="checkbox" id="checkTermCondition"
                                            {...register("checkTermCondition", {
                                                required: "Please check to agree"
                                                })} />
                                            <label className="form-check-label" htmlFor="checkTermCondition">
                                                    I Agree not to send spam and comply with WhatsApp Terms
                                            </label>
                                            {errors.checkTermCondition && <div className="invalid-feedback">{errors.checkTermCondition.message}</div>}
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between ">
                                            <NavLink to="/device" className="btn btn-sm btn-default w-25" type="button">Cancel</NavLink>
                                            <button className="btn btn-sm btn-primary w-25" type="submit">Next</button>
                                        </div>
                                    </form>
                                </div>
                            : page === 'qrcode' ?
                                <div className="col-md-12">
                                    {qrcode && !qrcodeScanned ?
                                        <div className="text-center">     
                                            <h5>Connect the number by scanning the QR code with the camera.</h5>
                                            <p>To prevent conflict, please close all existing WhatsApp Web open sessions</p>
                                            <QRCode size={200} value={qrcode} imageSettings={{
                                                src: process.env.REACT_APP_URL+"/wa-icon.svg",
                                                x: null,
                                                y: null,
                                                height: 48,
                                                width: 48,
                                                excavate: false,
                                            }}/>
                                        </div> 
                                        :
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            : page === 'authenticated' ?
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <h5>Your WhatsApp number is now connected.<br />
                                        You can start sending and receiving messages through the platform.
                                        </h5>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <NavLink to={`/device/${deviceId}/detail`} className="btn btn-sm btn-primary w-25">Show Device</NavLink>
                                    </div>
                                </div>
                            : null
                        }
                        </div>
                    </div>
                
                </div>
            </div>
        </Content>
             
    )
}
