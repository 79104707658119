import React, { useEffect, useState } from 'react'
import { Backdrop, Navbar, Sidebar } from '../components';
import useWindowDimensions from '../utils/useWindowDimensions';

const App = ({children}) => {
    const [width] = useWindowDimensions();
    const [sidebar, setSidebar] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [backdrop, setBackdrop] = useState(false);

    const collapseSidebar = () => {
        setSidebar(!sidebar)
        if (width < 992 && !sidebar) { setBackdrop(true) } else { setBackdrop(false) }
    };
    const openSidebarFolded = (open) => setSidebarOpen(open);
    const closeSidebar = () => {
        if (width < 992) {
            setSidebar(false);
            setBackdrop(false);
        }
    }

    const sidebarMobile = sidebar && width < 992 ? " sidebar-open" : "";
    const styleSidebar = width > 991 ? "main-wrapper sidebar-folded" : "main-wrapper";
    const mainStyle = sidebar ? "main-wrapper" + sidebarMobile : styleSidebar;
    const sidebarOpenStyle = sidebarOpen ? " open-sidebar-folded" : "";

    useEffect(() => {
        if (width > 991) {
            setSidebar(true)
            setBackdrop(false)
        }
        else {
            setSidebar(false)
        }
    }, [width])

    return (
        <div className={mainStyle + sidebarOpenStyle}>
            <Sidebar openSidebarFolded={openSidebarFolded} closeSidebar={closeSidebar} />
            <Backdrop show={backdrop} onClick={collapseSidebar} />
            <div className="page-wrapper">
            <Navbar collapseSidebar={collapseSidebar} />
            {children}
            </div>
        </div>
    )
}

const AppLayout = ({ component: Component}) => {
    return (
        <App match>
            <Component/>
        </App>
    );
};
  
export default AppLayout;