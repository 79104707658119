import React from 'react'

function Error500() {
    return (
        <div className="w-50 vh-100 pb-5 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center">
                <h1>500</h1>
                <p className="lead">Something went wrong.</p>
                <a href="/" className="btn btn-lg btn-primary fw-bold">Back to Home</a>
            </div>
        </div> 
    )
}

export default Error500
