import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, SwAlert } from '../../components';
import axios from '../../configs/axios';
import socket from '../../configs/socketIo';
import Content from '../../layouts/Content';
import Date from '../../utils/Date';

export default function Device() {
    const [devices, setDevices] = useState([]);

    const getDevices = async () => {
		try {
            let res = await axios.get('device')
            setDevices(res.data.data)
		} catch (e) {
			console.log(e)
		}
    }

    const deleteDevice = (e, deviceId) => {
        e.preventDefault()
        SwAlert.fire({
            title: 'Delete',
            text: 'Are you sure to delete this device?',
            showCancelButton: true,
            buttonsStyling: false,
            reverseButtons: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'w-25 btn btn-danger btn-sm',
                cancelButton: 'w-25 btn btn-default btn-sm me-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios.delete(`device/delete/${deviceId}`)
                    console.log(res)
                    if (res.data.meta.status === 'success') {
                        getDevices()
                        Alert.success('Perangkat berhasil dihapus.', {autoClose: true})
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

	const unauthDevice = (e, deviceId) => {
        e.preventDefault()
        SwAlert.fire({
            title: 'Unauthorize',
            text: 'Are you sure to unauthorize this device?',
            showCancelButton: true,
            buttonsStyling: false,
            reverseButtons: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'w-25 btn btn-danger btn-sm',
                cancelButton: 'w-25 btn btn-default btn-sm me-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios.post(`device/logout/${deviceId}`)
                    if (res.data.meta.status === 'success') {
                        syncDeviceStatus(deviceId)
                        Alert.success('Perangkat berhasil diunauthorize.', {autoClose: true})
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

	const syncDeviceStatus = async (deviceId) => {
        try {
            const res = await axios.get('device/' + deviceId);
            if(res.data.meta.status == "success"){
				let devicesTemp = [...devices];  
				var index = devicesTemp.findIndex(function(device) {
					return device.device_id == deviceId
				});
				devicesTemp[index] = res.data.data;                  
				setDevices(devicesTemp);  
			}
        } catch (e) {
            
        }
    }

    useEffect(() => {
        getDevices();
    }, [])

	useEffect(() => {
		socket.on("device-change-status", data => {
			let foundDevice = devices.some(device => device.device_id == data.device_id);
            if (foundDevice) {
                syncDeviceStatus(data.device_id)
            }
        });
    }, [devices]);

    return (
        <Content title="Device">
            <div className="col-lg-12 col-xl-12">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">WhatsApp Devices</h5>
                    <NavLink to="/device/create" className="btn btn-sm btn-primary">New Device</NavLink>
                </div>
                
                <div className="card">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center" width={50}>No</th>
                                    <th>Number</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {devices.length > 0 ?
                                    <>
                                        { devices.map((device, index) => 
                                        <tr key={index}>
                                            <td className="text-center">{index+1}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={process.env.REACT_APP_API_URL+'/devices/profile-pic/'+device.device_profile_pic} alt={device.device_name} width={32} height={32} className="rounded-circle me-2" />
                                                    <div className="d-block">
                                                        <p className="p-0 mb-1">{device.device_name}</p>
                                                        <small className="text-muted">{device.device_session ? device.device_number : 'Unauthorized'}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {device.device_status === 'online' ? 
                                                    <small className="d-flex align-items-center mb-1">
                                                        <i className="bi-circle-fill text-success me-1"/>Online
                                                    </small>
                                                :
                                                    device.device_status === 'offline' ?
                                                    <small className="d-flex align-items-center mb-1">
                                                                <i className="bi-circle-fill text-danger me-1" />{device.device_authorized === 'true' ? 'Offline' : 'Unauthorized'}
                                                    </small>
                                                :
                                                    <small className="d-flex align-items-center mb-1">
                                                        <div className="spinner-border text-secondary spinner-border-sm me-1" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>Loading...
                                                    </small>
                                                }
                                                
                                                {device.synced_at ?
                                                    <small className="text-muted">Synced at {Date.format(device.synced_at)}</small>
                                                : null}
                                                
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi-three-dots-vertical"></i>
                                                </button>
                                                    <ul className="dropdown-menu dropdown-option dropdown-menu-end">
                                                        {device.device_status === 'online' &&
                                                            <>
																<li>
																	<NavLink className="dropdown-item" to={`/device/${device.device_id}/detail`}>
																		<span className="icon"><i className="bi-eye-fill"></i></span>
																		Detail
																	</NavLink>
																</li>
																<li>
																	<a className="dropdown-item" href="#" onClick={(e) => unauthDevice(e, device.device_id)}>
																		<span className="icon"><i className="bi-box-arrow-left"></i></span>
																		Unauthorize
																	</a>
																</li>
															</>
                                                        }
                                                        
                                                        {device.device_authorized === 'false' &&
															<>
																<li>
																	<a className="dropdown-item" href={`/devices/authorize/${device.device_id}`}>
																		<span className="icon"><i className="bi-box-arrow-in-right"></i></span>
																		Authorize
																	</a>
																</li>
																<li>
																	<a className="dropdown-item" href="/#" onClick={(e) => deleteDevice(e, device.device_id)}>
																		<span className="icon"><i className="bi-trash-fill"></i></span>
																		Delete
																	</a>
																</li>
															</>
                                                        }
                                                </ul>
                                            </td>
                                        </tr>
                                        )}
                                    </>
                                    : 
                                    <tr>
                                        <td className="text-center" colSpan="4">
                                            <p className="text-muted">Belum ada perangkat</p>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Content>
    )
}
