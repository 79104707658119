import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, SwAlert } from '../../components';
import axios from '../../configs/axios';
import Content from '../../layouts/Content';

export default function ApiKey() {
    const [apiKeys, setApiKeys] = useState([]);

    const getApiKeys = async () => {
		try {
            let res = await axios.get('api-token')
            setApiKeys(res.data.data)
		} catch (e) {
			console.log(e)
		}
    }

    const deleteDevice = (e, id) => {
        e.preventDefault()
        SwAlert.fire({
            title: 'Delete',
            text: 'Are you sure to delete this data?',
            showCancelButton: true,
            buttonsStyling: false,
            reverseButtons: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'w-25 btn btn-danger btn-sm',
                cancelButton: 'w-25 btn btn-default btn-sm me-2'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    let res = await axios.delete(`api-token/delete/${id}`)
                    console.log(res)
                    if (res.data.meta.status === 'success') {
                        getApiKeys()
                        Alert.success('Api key berhasil dihapus.', {autoClose: true})
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

    useEffect(() => {
        getApiKeys();
    }, [])
    
    return (
        <Content title="Api Key">
            <div className="col-lg-12 col-xl-12">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Api Keys</h5>
                    <NavLink to="/api-key/create" className="btn btn-sm btn-primary">Create Api Key</NavLink>
                </div>
                <div className="card">
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-center" width={50}>No</th>
                                    <th>Api Name</th>
                                    <th>Api Token</th>
                                    <th>Last Used</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiKeys.length > 0 ?
                                    <>
                                        { apiKeys.map((api, index) => 
                                        <tr key={index}>
                                            <td className="text-center">{index+1}</td>
                                            <td>{ api.api_name }</td>
                                            <td>{ api.api_token }</td>
                                            <td>{ api.api_token.last_used_at ? api.api_token.last_used_at : '-' }</td>
                                            <td>
                                                <button type="button" className="btn btn-light btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bi-three-dots-vertical"></i>
                                                </button>
                                                <ul className="dropdown-menu dropdown-option dropdown-menu-end">
                                                    <li>
                                                        <NavLink className="dropdown-item" to={`/api-key/${api.api_id}/detail`}>
                                                            <span className="icon"><i className="bi-eye-fill"></i></span>
                                                            Detail
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/#" onClick={(e) => deleteDevice(e, api.api_id)}>
                                                            <span className="icon"><i className="bi-trash-fill"></i></span>
                                                            Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        )}
                                    </>
                                    : 
                                    <tr>
                                        <td className="text-center" colSpan="4">
                                            <p className="text-muted">Tidak ada data</p>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Content>
    )
}
