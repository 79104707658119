import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from '../contexts/Auth';
import * as Middleware from '../middleware';
import AppLayout from '../layouts/AppLayout';

import { ApiKey, ApiKeyCreate, Dashboard, Device, DeviceAuthorize, DeviceCreate, DeviceDetail, Error400, Login, Register } from '../views';

export default function ReactRouter() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route exact path="/login">
                        <Middleware.Guest render={ <Login/>}/>
                    </Route>
                    <Route exact path="/register">
                        <Middleware.Guest render={ <Register/>}/>
                    </Route>
                    <Route exact path="/">
                        <Middleware.Authenticated render={<AppLayout component={Dashboard}/>} />
                    </Route>
                    <Route exact path="/device/create">
                        <Middleware.Authenticated render={<AppLayout component={DeviceCreate}/>} />
                    </Route>
                    <Route exact path="/devices/authorize/:id">
                        <Middleware.Authenticated render={<AppLayout component={DeviceAuthorize}/>} />
                    </Route>
                    <Route exact path="/device">
                        <Middleware.Authenticated render={<AppLayout component={Device}/>} />
                    </Route>
                    <Route exact path="/device/:id/:page">
                        <Middleware.Authenticated render={<AppLayout component={DeviceDetail}/>} />
                    </Route>
 
                    <Route exact path="/api-key">
                        <Middleware.Authenticated render={<AppLayout component={ApiKey}/>} />
                    </Route>
                    <Route exact path="/api-key/create">
                        <Middleware.Authenticated render={<AppLayout component={ApiKeyCreate}/>} />
                    </Route>
                    <Route component={Error400}/>
                </Switch>
            </AuthProvider>
        </BrowserRouter>
    )
}
